export const PatternBasePropertiesElemMain = {
    main: {
        name: {
            name: 'name',
            unit: '',
            required: false,
            relation: false,
            type: 'string',
        },
        hiddenElem: {
            name: 'hiddenElem',
            unit: '',
            required: false,
            relation: false,
            type: 'checkbox'
        },
        // content: {
        //     name: 'content',
        //     unit: '',
        //     required: false,
        //     relation: false,
        //     type: 'textarea'
        // },
        contentWidget: {
            name: 'contentWidget',
            unit: '',
            required: false,
            relation: false,
            type: 'ContentWidget'
        },
        attr_title: {
            name: 'attr_title',
            unit: '',
            required: false,
            relation: false,
            type: 'string',
        },
    }
}
