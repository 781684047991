export const patternAddPropertiesA = {
    main: {
        main: {
            attr_href: {
                name: 'attr_href',
                required: false,
                type: 'string'
            },
            attr_target: {
                name: 'attr_target',
                required: false,
                type: "select",
                default: '_self',
                options: [
                    {
                        name: '_self',
                        code: '_self'
                    },
                    {
                        name: '_blank',
                        code: '_blank'
                    }
                ]
            }
        }
    }
}
