import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueCookies from 'vue-cookies'

export const defaultLocale = 'en'
export const availableLang = ['en', 'ru']

Vue.use(VueI18n)

let currentLang = defaultLocale

const cookLang = VueCookies.get('lang')

if (cookLang) {
  currentLang = cookLang
}

if (!availableLang.includes(currentLang)) {
  currentLang = 'en'
}


function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  locale: currentLang,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
