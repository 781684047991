import { statService } from "@/helpers/fakebackend/stats.service"
import store from '@/state/store'
import {clone} from "lodash";

export const state = {
    /**
     * Object with statistics for projects
     * project_id: {
     *  viewsInCurrentMonth: 0,
     *  viewsInPrevMonth: 0,
     *  dataForCharts: {
     *      month: {
     *          2022 : {
     *              dateLoad: 123123123, / unix
     *              data: [1,2,3,4,5,6,7,8,9,10,11,12]
     *          }
     *      },
     *      day: {
     *          12: {
     *              dateLoad: 123123123, / unix
     *              data: [1,2,3,4,5,6,7,8,9,10,11,12]
     *          }
     *      }
     *  }
     *  tags: {
     *      tag_id: {
     *          viewsInCurrentMonth: 0,
     *          viewsInPrevMonth: 0,
     *          conversionInCurrentMonth: 0,
     *          conversionInPrevMonth: 0,
     *          dataForCharts: {
     *              viewsInCurrentMonth: {
     *                 group: [ // month/day/...
     *                      1,2,3,4,5,6,7,8,9,10,11,12
     *                  ],...
     *              },
     *          }
     *      }
     *  }
     *
     * }
     */
    cacheTime: 300, // sec
    patternForTags: {
        viewsInCurrentMonth: 0,
        viewsInPrevMonth: 0,
        conversionInCurrentMonth: 0,
        conversionInPrevMonth: 0,
        dataForCharts: {
            // event_name: {
            //     month: {},
            //     day: {}
            // }
        }
    },
    patternGroups: {
        month: {},
        day: {}
    },
    statistics: {
        viewsInCurrentMonth: {
            date: 0,
            data: 0
        },
        viewsInPrevMonth: {
            date: 0,
            data: 0
        },
        dataForCharts: {
            month: {},
            day: {}
        },
        tags: {}
    }
}

export const actions = {
    changeProject: function({dispatch}) {
        // global loader...
        dispatch('getCountViewsInCurrentMonth')
    },
    loadViewsInPrevMonth: function({ state, commit }) {
        let time = Math.floor(Date.now()/1000)
        let prevMonth = new Date(Date.now())
        prevMonth.setMonth(prevMonth.getMonth() - 1);

        if ( state.statistics.viewsInPrevMonth !== undefined &&
            state.statistics.viewsInPrevMonth.date > time - state.cacheTime ) {
            return state.statistics.viewsInPrevMonth
        }

        return statService.getStatForChart({
            project_id: store.getters['projects/getCurrentProjects'].id,
            event: 'init',
            date: prevMonth.getTime(),
            group: 'month',
            rangeFrom: 'first day of this month',
            rangeTo: 'P1M',
            checkDate: false
        }).then(data => {
            commit('setViewsInPrevMonth', {date: time, data: data[0] })
            return state.statistics.viewsInCurrentMonth
        })
    },
    loadViewsInCurrentMonth: function ({state, commit}) {
        let time = Math.floor(Date.now()/1000)

        if ( state.statistics.viewsInCurrentMonth !== undefined &&
            state.statistics.viewsInCurrentMonth.date > time - state.cacheTime ) {
            return state.statistics.viewsInCurrentMonth
        }

        return statService.getStatForChart({
            project_id: store.getters['projects/getCurrentProjects'].id,
            event: 'init',
            date: time,
            group: 'month',
            rangeFrom: 'first day of this month',
            rangeTo: 'P1M',
            checkDate: false
        }).then(data => {
            commit('setViewsInCurrentMonth', {date: time, data: data[0] })
            return state.statistics.viewsInCurrentMonth
        })
    },
    getDateString: () => ({ group, unixtime }) => {
        let DateObject = new Date(unixtime)
        if (group === 'month') {
            return DateObject.getMonth() + '.' + DateObject.getYear()
        } else if (group === 'day') {
            return DateObject.getDay() + '.' + DateObject.getMonth() + '.' + DateObject.getYear()
        }
    },
    getDataForCharts: function({state, commit, dispatch}, {group , date}) {
        let saveDate = dispatch('getDateString', {group, unixtime: date})

        if ( state.statistics.dataForCharts[group][saveDate] !== undefined &&
            state.statistics.dataForCharts[group][saveDate].date > Math.floor(Date.now()/1000 - state.cacheTime) ) {
            return state.statistics.dataForCharts[group][saveDate]
        }

        return statService.getStatForChart({
            project_id: store.getters['projects/getCurrentProjects'].id,
            event: 'init',
            date: date,
            group: group
        }).then(data => {
            let result = {date: Math.floor(Date.now()/1000), data: data}
            commit('setProjectDataForCharts', { group: group, date: saveDate, data: result })
            return result
        })
    },
    getDataForChartsTag: function({state, commit, dispatch}, {tag_id, group , date, eventName}) {

        if (state.statistics.tags[tag_id] === undefined) {
            // await commit('createTagInStatistics', tag_id)
            state.statistics.tags[tag_id] = clone(state.patternForTags)
            state.statistics.tags[tag_id].dataForCharts[eventName] = clone(state.patternGroups)
        } else if (state.statistics.tags[tag_id][eventName] === undefined) {
            state.statistics.tags[tag_id].dataForCharts[eventName] = clone(state.patternGroups)
        }

        let saveDate = dispatch('getDateString', {group, unixtime: date})
        if (state.statistics.tags[tag_id].dataForCharts[eventName][group][saveDate] !== undefined &&
            state.statistics.tags[tag_id].dataForCharts[eventName][group][saveDate].date > Math.floor(Date.now()/1000 - state.cacheTime) ) {
            return state.statistics.tags[tag_id].dataForCharts[eventName][group][saveDate]
        }

        return statService.getStatForChart({
            project_id: store.getters['projects/getCurrentProjects'].id,
            event: eventName,
            date: date,
            group: group,
            tag_id: tag_id
        }).then(data => {
            // let sum = await dispatch('calculateSumFromCharts', data)

            let sum = 0
            data.forEach((elem) => {
                sum += parseFloat(elem)
            })

            let result = {date: Math.floor(Date.now()/1000), data: data, sum}
            commit('setTagProjectDataForCharts', { tag_id, group, date: saveDate, data: result, eventName })
            return result
        })
    }
}

export const getters = {
    getStatisticsProject(state) {
        return state.statistics
    },
    getStatisticsTag(state, {tag_id}) {
        return state.statistics.tags[tag_id]
    },
    getViewsInCurrentMonth: function (state) {
        return state.statistics.viewsInCurrentMonth
    },
    getViewsInPrevMonth: function (state) {
        return state.statistics.viewsInPrevMonth
    },
    getDataForChartsMonth: function(state) {
        return state.statistics.dataForCharts.month
    }
}

export const mutations = {
    createProject(state) {
        state.statistics = {}
    },
    setViewsInCurrentMonth(state, data) {
        state.statistics.viewsInCurrentMonth = data
    },
    setViewsInPrevMonth(state, data) {
        state.statistics.viewsInPrevMonth = data
    },
    setProjectDataForCharts(state, {group, date, data}) {
        state.statistics.dataForCharts[group][date] = data
    },

// TAGS
    createTagInStatistics(state, {tag_id}) {
        state.statistics.tags[tag_id] = clone(state.patternForTags)
    },
    setTagViewsInCurrentMonth(state, {tag_id, count}) {
        state.statistics.tags[tag_id].viewsInCurrentMonth = count
    },
    setTagViewsInPrevMonth(state, {tag_id, count}) {
        state.statistics.tags[tag_id].viewsInPrevMonth = count
    },
    setTagConversionInCurrentMonth(state, {tag_id, count}) {
        state.statistics.tags[tag_id].conversionInCurrentMonth = count
    },
    setTagConversionInPrevMonth(state, {tag_id, count}) {
        state.statistics.tags[tag_id].conversionInPrevMonth = count
    },
    setTagProjectDataForCharts(state, {tag_id, group, date, data, eventName}) {
        state.statistics.tags[tag_id].dataForCharts[eventName][group][date] = data
    },
}