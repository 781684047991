export const patternAddPropertiesImg = {
    main: {
        main: {
            attr_src: {
                name: 'attr_src',
                required: false,
                type: 'image'
            },
        }
    },
    css: {
        background: {
            backgroundTitle: null,
            backgroundImage: null
        }
    }
}
