export const patternAddPropertiesTextarea = {
    main: {
        form: {
            field_user_name: {
                name: 'field_user_name',
                required: false,
                type: 'string'
            },
            // attr_name: {
            //     name: 'attr_name',
            //     required: false,
            //     type: 'string'
            // },
            attr_required: {
                name: 'attr_required',
                required: false,
                type: "checkbox",
            },
            attr_maxlength: {
                name: 'attr_maxlength',
                unit: null,
                required: false,
                type: 'number'
            },
            attr_placeholder: {
                name: 'attr_placeholder',
                unit: '',
                required: false,
                type: 'string'
            }
        }
    },
    js: {
        events: {
            onValidateInvalidDataON: {
                fields: []
            },
            onValidateInvalidDataOff: {
                fields: []
            }
        },
        actions: {
            validateMsg: {
                parent: ['onValidateInvalidDataON',], //события в sendForm
                child: {
                    widgets: 'validateMsg'
                }
            },
        }
    }
}
