export const patternAddPropertiesMainDiv = {
    main: {
        main: {
            targetCss: {
                name: 'targetCss',
                unit: '',
                required: false,
                type: 'TargetMainDivWidget',
                default: 'body'
            },
            // type: {
            //     name: 'type',
            //     required: true,
            //     relation: false,
            //     type: "select",
            //     description: "",
            //     tooltip: "",
            //     options: [
            //         {
            //             name: 'popap',
            //             code: 'popap'
            //         },
            //         {
            //             name: 'tooltip',
            //             code: 'tooltip'
            //         },
            //         {
            //             name: 'обычный',
            //             code: 'default'
            //         }
            //     ],
            //     callback: function(vue, value) {
            //         if (value === 'tooltip') {
            //             vue.$delete(vue.elem.css, 'position')
            //             // vue.TDESaddServiceElem('serviceArrow', vue.indexElem)
            //         } else {
            //             vue.$set(vue.elem.css, 'position', 'absolute')
            //             // vue.TDESDelServiceElem('serviceArrow', vue.indexElem)
            //         }
            //     }
            // },

            // serviceArrow: {
            //     name: 'Стрелка указатель',
            //     required: false,
            //     type: "checkbox",
            //     description: "Расположение стрелки будет расчитываться автоматически",
            //     tooltip: "",
            //     relation: {
            //         block: 'main',
            //         properties: 'type',
            //         value: ['tooltip']
            //     },
            //     callback: function(vue, value) {
            //         if (value === true) {
            //             vue.TDESaddServiceElem('serviceArrow', vue.indexElem)
            //         } else {
            //             vue.TDESDelServiceElem('serviceArrow', vue.indexElem)
            //         }
            //     }
            // },

        }
    },
    // css: {
    //     position: {
    //         position: {
    //             name: 'position',
    //             required: true,
    //             relation: {
    //                 block: 'main',
    //                 properties: 'type',
    //                 value: ['popap', 'relative']
    //             },
    //             type: "select",
    //             description: "",
    //             tooltip: "",
    //             options: [
    //                 {
    //                     name: 'absolute',
    //                     code: 'absolute'
    //                 },
    //                 {
    //                     name: 'fixed',
    //                     code: 'fixed'
    //                 },
    //                 {
    //                     name: 'center',
    //                     code: 'center'
    //                 },
    //                 {
    //                     name: 'tooltip',
    //                     code: 'tooltip'
    //                 },
    //                 {
    //                     name: 'relative',
    //                     code: 'relative'
    //                 },
    //             ],
    //             callback: function(vue, value) {
    //                 if (value === 'tooltip') {
    //                     vue.TDESaddServiceElem('serviceArrow', vue.indexElem)
    //                 } else {
    //                     vue.TDESDelServiceElem('serviceArrow', vue.indexElem)
    //                 }
    //             }
    //         }
    //     }
    // }
    js: {
        actions: {
            // tooltipPosition: {
            //     name: 'Расчет позиции как подсказки',
            //     parent: [],
            //     child: {
            //         widgets: 'TooltipPositionWidget'
            //     }
            // }
        }
    }
}
