export const PatternAddPropertiesRadioButton = {
    main: {
        form: {
            field_user_name: {
                name: 'field_user_name',
                required: false,
                type: 'string'
            }
        }
    }
}
