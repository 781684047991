import axios from 'axios'
import store from "@/state/store";
import i18n from '@/i18n'

export default class ApiBack {
    HOST = process.env.VUE_APP_SERVER

    async sendRequest (request)
    {
        if (request.headers === undefined) {
            request.headers = {}
        }
        // let param = {}
        if (request.method === 'post') {
            // if (!store.getters['init/getCsrf']) {
            //     await store.dispatch('init/init')
            // }
            // request.properties[store.getters['init/getCsrf'].param] = store.getters['init/getCsrf'].token
            if (request.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
                let pairs=[];
                for (var key in request.properties) {
                    pairs.push(key+"="+encodeURIComponent(request.properties[key]));
                }
                request.data=pairs.join("&");
            } else {
                request.data=request.properties
            }
        }

        // if ((request.method === 'get' || request.method === 'GET') && request.properties) {
        //     param = request.properties
        // }
        request.url = this.HOST + request.path
        request.withCredentials = true

        request.headers.lang = i18n.locale

        return axios(request).catch(error => {
            if (error.response !== undefined && error.response.status === 400 && request.double === undefined) {
                return store.dispatch('init/init').then(() => {
                    request.double = true
                    return this.sendRequest(request)
                })
            }

            if (error.response !== undefined && error.response.status === 403) {
                store.dispatch('init/init')
                return
            }

            store.dispatch('notification/error', error.message);

            throw error
        })
    }
}
