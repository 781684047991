export const PatternBasePropertiesElemJS = {
    events: {
        click: {
            name: 'click',
            fields: {
                currentTarget: {
                    name: 'currentTarget',
                    required: true,
                    relation: {},
                    type: 'checkbox',
                    labelCols: 0
                }
            }
        },
        // hover: {
        //     name: 'Hover',
        //     fields: []
        // },
        setTimeout: {
            name: 'setTimeout',
            fields: {
                timeout: {
                    name: 'timeout',
                    required: true,
                    relation: {},
                    type: 'number',
                    unit: ['ms'],
                    tooltip: 'tooltip timeout'
                }
            }
        },
        init: {
            name: 'init',
            fields: []
        },
        onShow: {
            name: 'onShow',
            fields: {}
        },
        onHide: {
            name: 'onHide',
            fields: {}
        },
    },
    actions: {
        changeContent: {
            parent: [],
            child: {
                widgets: 'changeContent'
            }
        },
        css: {
            parent: [],
            child: {
                widgets: 'css'
            }
        },
        // webhook: {
        //     parent: [],
        //     child: {
        //         widgets: null
        //     }
        // },
        // customJs: {
        //     parent: [],
        //     child: {
        //         widgets: null
        //     }
        // },
        hideTag: {
            parent: [],
            child: {
                widgets: 'getElemWithAnimate'
            }
        },
        showTag: {
            parent: [],
            child: {
                widgets: 'getElemWithAnimate'
            }
        },
        sendForm: {
            parent: [],
            child: {
                widgets: 'sendForm'
            }
        },
        // autoValidateForm: {
        //     parent: ['validationError'], //события в sendForm
        //     child: {
        //         widgets: 'autoValidateForm'
        //     }
        // },
        toggleShow: {
            // name: "Отображать только при наведении на указанный элемент",
            parent: [],
            child: {
                widgets: 'toggleShow'
            }
        },
        bodyScroll: {
            // name: "Управление скролом body",
            parent: [],
            child: {},
            fields: {
                bodyScroll: {
                    name: 'bodyScroll',
                    required: true,
                    relation: {},
                    type: 'checkbox',
                    labelCols: 0,
                    mounted: false
                }
            }
        },
        tooltipPosition: {
            // name: 'Расчет позиции как подсказки',
            parent: [],
            child: {
                widgets: 'TooltipPositionWidget'
            }
        }
    }
}
