<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  data: function() {
    return {
      isLoading: true
    }
  },
  computed: {
    notification() {
      return this.$store.getters['notification/getNotificationType']
    }
  },
  watch: {
    notification: function(notification) {
      this.makeToast(notification, this.$store.getters['notification/getNotificationMsg'])
    }
  },
  created() {
    // this.$store.dispatch('init/init')
  },
  methods: {
    makeToast(variant = null, text) {
      let title = ''
      if (variant === 'danger') {
        title = this.$t('General.Toast.Title.Error')
      } else if (variant === 'success') {
        title = this.$t('General.Toast.Title.Success')
      }
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true
      });
        this.$store.dispatch('notification/clear');
    }
  },
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  mounted() {
    this.$store.getters['init/getProcessing'].then(() => {
      document.getElementById('mainLoading').style.display = 'none'
    }, () => {
      document.getElementById('mainLoading').style.display = 'none'
    })
  }
};
</script>
