import { userService } from '@/helpers/fakebackend/user.service';
import router from '../../router/index'
import store from "@/state/store";

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? {
    status: { loggeduser: false }, user }
    : { status: {}, user: null };

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    async login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });

        return userService.login(email, password)
            .then(
                user => {
                    dispatch('saveUserData', user)

                    store.dispatch('init/init').then(() => {
                        router.push('/');
                    })

                    // store.dispatch('projects/init')
                    // router.push('/');
                    return user
                },
                error => {
                    commit('loginFailure', error);
                    return new Promise((resolve, reject) => {
                        reject(error)
                    })
                }
            )
    },
    saveUserData({commit}, user ) {
        localStorage.setItem('user', JSON.stringify(user))
        commit('loginSuccess', user)
    },
    // Logout the user
    logout({ commit }) {
        return userService.logout()
            .then(
                () => {
                    localStorage.removeItem('user');
                    localStorage.removeItem('tagsData');
                    localStorage.removeItem('projectId');
                    localStorage.removeItem('newTagServiceData');
                    commit('logout');
                }
            )
    },
    // register the user
    // eslint-disable-next-line no-empty-pattern
    registeruser({}, user) {
        // commit('registerRequest', user);
        return userService.register(user)
            // .then(
            //     user => {
            //         commit('registerSuccess', user);
            //         dispatch('notification/success', 'Registration successful', { root: true });
            //         router.push('/');
            //     },
            //     error => {
            //         commit('registerFailure', error);
            //         if (error['signupform-email'] !== undefined) {
            //             dispatch('notification/error', this.$t('Authentication.Register.Errors.EmailExists'), { root: true });
            //             return
            //         }
            //         dispatch('notification/error', this.$t('General.Errors.UnexpectedError'), { root: true });
            //         // dispatch('notification/error', error, { root: true });
            //     }
            // );
    },

    // eslint-disable-next-line no-empty-pattern
    requestPasswordReset({}, user) {
        return userService.requestPasswordReset(user)
    },

    // eslint-disable-next-line no-empty-pattern
    resetPassword({}, user) {
        return userService.passwordReset(user)
    }
};

export const getters = {
    getLogin(state) {
        return state.status.loggeduser
    },
    getUser(state) {
        return state.user
    }
}

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = { loggeduser: false };
        state.user = null;
    },
    logout(state) {
        state.status = { loggeduser: false };
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};

