// import { authHeader } from './auth-header';
import api from "@/helpers/fakebackend/api";

export const userService = {
    login,
    logout,
    register,
    requestPasswordReset,
    passwordReset,
    activateUser
    // getAll,
};

async function login(email, password) {
    const request = {
        method: 'post',
        path: 'user/sign-in/login',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: {
            identity: email,
            password: password
        }
    }

    return (new api()).sendRequest(request).then(response => {

        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data)
            }
            if (response.data.status === 'error') {
                reject(response.data)
            }

            // reject(new Error('error')) обработка ошибки
        });
    })
}

function logout() {
    const request = {
        method: 'post',
        path: 'user/sign-in/logout',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: {}
    }

    return (new api()).sendRequest(request).then(response => {

        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }

            // reject(new Error('error')) обработка ошибки
        });
    })
}

function register(user) {
    const request = {
        method: 'post',
        path: 'user/sign-in/signup',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: {
            email: user.email,
            password: user.password
        }
    }
    return (new api()).sendRequest(request).then(response => {

        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function requestPasswordReset(user) {
    const request = {
        method: 'post',
        path: 'user/sign-in/request-password-reset',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: {
            email: user.email
        }
    }
    return (new api()).sendRequest(request).then(response => {

        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function passwordReset(user) {
    const request = {
        method: 'post',
        path: 'user/sign-in/reset-password',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: {
            email: user.email,
            password: user.password,
            code: user.code
        }
    }
    return (new api()).sendRequest(request).then(response => {

        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function activateUser(token) {
    const request = {
        method: 'get',
        path: 'user/sign-in/activation',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: {
            token: token
        }
    }
    return (new api()).sendRequest(request).then(response => {

        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}
// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 // auto logout if 401 response returned from api
//                 logout();
//                 location.reload(true);
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         return data;
//     });
// }
