export const PatternAddPropertiesCheckboxGroup = {
    main: {
        main: {
            content: null
        },
        form: {
            field_user_name: {
                name: 'field_user_name',
                required: false,
                type: 'string'
            },
            attr_name: {
                name: 'attr_name',
                required: false,
                type: 'string'
            },
            attr_required: {
                name: 'attr_required',
                required: false,
                type: "checkbox",
                callback: (vue, value, actions) => {
                    let elems = vue.getAllChildElem(vue.indexElem, {name: ['checkbox']})
                    elems.forEach(elemId => {
                        actions.push({obj: vue.tagData.htmlElements[elemId].main, nameProp: 'attr_required', val: value, type: 'set'})
                    })
                }
            },
            CheckboxGroupWidget: {
                name: "CheckboxGroup",
                type: "CheckboxGroupWidget",
                relation: false,
                required: false
            },
        }
    },
    js: {
        events: {
            onValidateInvalidDataON: {
                fields: []
            },
            onValidateInvalidDataOff: {
                fields: []
            }
        },
        actions: {
            validateMsg: {
                parent: ['onValidateInvalidDataON',], //события в sendForm
                child: {
                    widgets: 'validateMsg'
                }
            },
        }
    }
}
