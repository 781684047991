import api from '../../helpers/fakebackend/api';
import store from '@/state/store'

export const state = {
    csrf: null,
    processing: null
}

export const actions = {
    async init({ commit, getters, dispatch }) {
        if (getters.getProcessing !== null) {
            return getters.getProcessing
        }

        const promise = (new api()).sendRequest({ method: 'get', path: 'site/init' }).then(response => {
            commit('setCsrf', response.data.data.csrf)
            commit('setProcessing', null);

            const loggeduser = localStorage.getItem('user');
            if (!loggeduser && response.data.data.user !== null) {
                store.dispatch('authfack/saveUserData', response.data.data.user)
            }

            if (response.data.data.user !== null) {
                dispatch('onlyAuthInit', response.data.data)
            }

            return response.data.data
        }).catch((error) => {
            throw error
        })

        commit('setProcessing', promise)
        return promise
    },
    // eslint-disable-next-line no-empty-pattern
    onlyAuthInit({}, data) {
        store.dispatch('projects/init', data.projects)
    }
};

export const getters = {
    getCsrf(state) {
        return state.csrf
    },
    getProcessing(state) {
        return state.processing
    }
}

export const mutations = {
    setCsrf(state, csrf) {
        state.csrf = csrf;
    },
    setProcessing(state, status) {
        state.processing = status
    }
};

