import api from "@/helpers/fakebackend/api";

export const state = {
    gallery: null,
    requestImage: false,
    responseImage: null
}

export const mutations = {
    setGallery(state, value) {
        state.gallery = value
    },
    pushGallery(state, value) {
        state.gallery.push(value)
    },
    setRequestImage(state, value) {
        state.requestImage = value
    },
    setResponseImage(state, value) {
        state.responseImage = value
    },
    setRequestNumber(state, value) {
        state.requestNumber = value
    },
    deleteImg(state, img) {
        for (let i in state.gallery) {
            if (state.gallery[i].id === img.id) {
                state.gallery.splice(i, 1)
                break
            }
        }
    }
}

export const getters = {
    getGallery(state) {
        return state.gallery
    },
    getRequestImage(state) {
        return state.requestImage
    }
}

export const actions = {
    requestImage({ commit }) {
        // let number = getters.getRequestImage
        // number++
        commit('setRequestImage', 'true')
        // return number
    },
    pushItemGallery({ commit }, item) {
        commit('pushGallery', item)
    },
    async getGallery({ state, dispatch }) {
        return new Promise((resolve) => {
            if (state.gallery !== null) {
                resolve(state.gallery)
                return
            }
            dispatch('getGalleryFromServer').then(() => {
                resolve(state.gallery)
            })
        });
    },

    async getGalleryFromServer({ commit, dispatch }) {
        const request = {
            method: 'get',
            path: 'user/user-file/get-gallery',
            properties: {}
        }

        return (new api()).sendRequest(request).then(response => {
            if (response.data.status === 'success') {
                commit('setGallery',  response.data.data );
            }
            if (response.data.status === 'error') {
                dispatch('notification/error', 'error', { root: true }); //@TODO: текст ошибки
            }
        })
    },

    async deleteImg({ commit, dispatch }, img) {
        const request = {
            method: 'post',
            path: 'user/user-file/delete-file?id=' + img.id,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            properties: {}
        }

        return (new api()).sendRequest(request).then(response => {
            if (response.data.status === 'success') {
                commit('deleteImg',  img );
            }
            if (response.data.status === 'error') {
                dispatch('notification/error', 'error', { root: true }); //@TODO: текст ошибки
            }
        })
    },

    async copyImg({ dispatch }, img) {
        const request = {
            method: 'post',
            path: 'user/user-file/copy-img',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            properties: img
        }

        return (new api()).sendRequest(request).then(response => {
            if (response.data.status === 'success') {
                // commit('deleteImg',  img );
            }
            if (response.data.status === 'error') {
                dispatch('notification/error', 'error', { root: true }); //@TODO: текст ошибки
            }
        })
    }
}
