export const patternAddPropertiesRow = {
    main: {
        main: {
            rowWidget: {
                name: 'rowWidget',
                unit: '',
                required: false,
                relation: false,
                type: 'RowWidget'
            },
        }
    }
}
