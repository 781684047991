export const state = {
    processSavingLocale: false,
    processSavingServer: false
}

export const mutations = {
    savingLocaleOn(state) {
        state.processSavingLocale = true
    },
    savingLocaleOff(state) {
        state.processSavingLocale = true
    },
    savingServerOn(state) {
        state.processSavingServer = true
    },
    savingServerOff(state) {
        state.processSavingServer = false
    }
}

export const getters = {
    getStatusSavingServer(state) {
        return state.processSavingServer;
    },
}

export const actions = {

}
