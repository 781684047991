import api from "@/helpers/fakebackend/api";

export const projectsService = {
    getProjects,
    getProject,
    createProject
};

async function getProjects() {
    const request = {
        method: 'get',
        path: 'tags/project/index',
        headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: {}
    }

    return (new api()).sendRequest(request).then(response => {

        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }

            // reject(new Error('error')) обработка ошибки
        });
    })
}

function getProject(project_id) {
    const request = {
        method: 'get',
        path: 'tags/project/view',
        headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: {
            id: project_id
        }
    }

    return (new api()).sendRequest(request).then(response => {

        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }

            // reject(new Error('error')) обработка ошибки
        });
    })
}

function createProject(data) {
    const request = {
        method: 'post',
        path: 'tags/project/create',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: data
    }

    return (new api()).sendRequest(request).then(response => {

        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }

            // reject(new Error('error')) обработка ошибки
        });
    })
}
