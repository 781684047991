import { patternBasePropertiesElem } from '@/helpers/constructor/patterns/PatternBasePropertiesElem'
// import { patternAddPropertiesDiv } from '@/helpers/constructor/patterns/PatternAddPropertiesDiv'
import { patternAddPropertiesMainDiv } from '@/helpers/constructor/patterns/PatternAddPropertiesMainDiv'
import { patternAddPropertiesInput } from '@/helpers/constructor/patterns/PatternAddPropertiesInput'
import { patternAddPropertiesTextarea } from '@/helpers/constructor/patterns/PatternAddPropertiesTextarea'
import { patternAddPropertiesWidgetAttach } from '@/helpers/constructor/patterns/PatternAddPropertiesWidgetAttach'
import { patternAddPropertiesImg } from '@/helpers/constructor/patterns/PatternAddPropertiesImg'
import { PatternAddPropertiesSelect } from '@/helpers/constructor/patterns/PatternAddPropertiesSelect'
import { patternAddPropertiesA } from '@/helpers/constructor/patterns/PatternAddPropertiesA'
import { PatternAddPropertiesRadioGroup } from '@/helpers/constructor/patterns/PatternAddPropertiesRadioGroup'
import { PatternAddPropertiesRadioButton } from '@/helpers/constructor/patterns/PatternAddPropertiesRadioButton'
import { patternAddPropertiesRow } from '@/helpers/constructor/patterns/PatternAddPropertiesRow'
import { patternAddPropertiesCol } from '@/helpers/constructor/patterns/PatternAddPropertiesCol'
import { patternAddPropertiesIcon } from '@/helpers/constructor/patterns/PatternAddPropertiesIcon'
import {PatternAddPropertiesCheckboxGroup} from "@/helpers/constructor/patterns/PatternAddPropertiesCheckboxGroup";
import {PatternAddPropertiesCheckbox} from "@/helpers/constructor/patterns/PatternAddPropertiesCheckbox";
import { patternAddPropertiesLottie } from '@/helpers/constructor/patterns/PatternAddPropertiesLottie'
import { patternAddPropertiesSlider } from '@/helpers/constructor/patterns/PatternAddPropertiesSlider'

import lodash from 'lodash'



const elemSettingsMap = {
    tag: {
        // div: patternAddPropertiesDiv,
        input: patternAddPropertiesInput,
        img: patternAddPropertiesImg,
        textarea: patternAddPropertiesTextarea,
        'swiper-container': patternAddPropertiesSlider
    },
    name: {
        mainDiv: patternAddPropertiesMainDiv,
        widgetAttach: patternAddPropertiesWidgetAttach,
        a: patternAddPropertiesA,
        selectCustom: PatternAddPropertiesSelect,
        radioGroup: PatternAddPropertiesRadioGroup,
        radiobutton: PatternAddPropertiesRadioButton,
        checkboxGroup: PatternAddPropertiesCheckboxGroup,
        icon: patternAddPropertiesIcon,
        checkbox: PatternAddPropertiesCheckbox,
        lottie: patternAddPropertiesLottie
    },
    type: {
        row: patternAddPropertiesRow,
        col: patternAddPropertiesCol
    }
}

export const state = {
    patternPropertiesElem: {},
}

export const mutations = {
    setPropElem(state, { name, value }) {
        state.patternPropertiesElem[name] = value
    }
}

export const getters = {
}

export const actions = {

     async getPatterns({ commit, dispatch },  elem ) {
         if (state.patternPropertiesElem[elem.name] !== undefined) {
             return state.patternPropertiesElem[elem.name]
         }

         let propsElem = {}

         if (elemSettingsMap.name[elem.name] !== undefined) {
             propsElem = await dispatch('merge', { name: elem.name, baseProp: patternBasePropertiesElem, addObjProp: elemSettingsMap.name[elem.name] })
         } else if (elemSettingsMap.tag[elem.tag]  !== undefined) {
             propsElem = await dispatch('merge', { name: elem.name, baseProp: patternBasePropertiesElem, addObjProp: elemSettingsMap.tag[elem.tag] })
         } else if (elemSettingsMap.type[elem.type]  !== undefined) {
             propsElem = await dispatch('merge', { name: elem.name, baseProp: patternBasePropertiesElem, addObjProp: elemSettingsMap.type[elem.type] })
         } else {
             commit('setPropElem', { name: elem.name, value: patternBasePropertiesElem })
             propsElem = patternBasePropertiesElem
         }

         return propsElem
    },
    merge({ commit }, { name, baseProp, addObjProp }) {
        let base = lodash.cloneDeep(baseProp) //{ ...baseProp }
        let addObj = addObjProp //{...addObjProp }

        for (let typeName in addObj) {
            if (base[typeName] === undefined) { // проверяем существование раздела (main, css, js)
                base[typeName] = addObj[typeName]
            } else {
                for (let name in addObj[typeName]) {
                    if (addObj[typeName][name] === null) {
                        delete base[typeName][name]
                        continue
                    }
                    if (base[typeName][name] === undefined) {
                        base[typeName][name] = addObj[typeName][name]
                    } else {
                        base[typeName][name] = Object.assign(base[typeName][name], addObj[typeName][name])
                    }

                    for (let prop in base[typeName][name]) {
                        if (base[typeName][name][prop] === null) {
                            delete base[typeName][name][prop]
                        }
                    }
                }

            }
        }
        commit('setPropElem', { name, value: base })
        return base
    }
}
