// import api from "@/helpers/fakebackend/api";
import lodash, {isString} from "lodash";
import api from "@/helpers/fakebackend/api";
import Vue from 'vue'


const patternTag = {
    id: '0',
    name: "",
    type: '0',
    project_id: '0',
    tag_source_id: null,
    status: '0',
    order: '0',
    version: '0',
    source: null,
    public_tag_source_id: null,
    tTagGroupTriggers: [],
    tagSourceId: {
        id: '0',
        name: null,
        version: null,
        tagFieldName: []
    },
    // dataControl: {
    //     fieldsTag: []
    // },
    tagHandlers: {}
}

const patternGroupTag = {
    id: '0',
    tag_id: '0',
    order: '0',
    sequence: '0',
    exception: '0',
    status: '0',
    triggersInGroups: []
}

const patternTriggerInGroup = {
    id: '0',
    trigger_id: '0',
    group_id: '0',
    order: '0',
    lead_time: 'page',
    lead_time_text: '',
    trigger: {}
}

export const state = {
    tag: {}, //newTagServiceData ? newTagServiceData : patternTag
    localStorageHaveTag: false
}

export const mutations = {
    setTag(state, tag) {
        state.tag = tag
    },
    setTagName(state, name) {
        state.tag.name = name
    },
    addGroupTrigger(state, value) {
        state.tag.tTagGroupTriggers.push(value)
    },
    addTriggerInGroup(state, { groupIndex, newTriggerInGroup, indexElem } ) {
        if (indexElem === undefined) {
            state.tag.tTagGroupTriggers[groupIndex].triggersInGroups.push(newTriggerInGroup)
        } else {
            state.tag.tTagGroupTriggers[groupIndex].triggersInGroups.splice( indexElem, 0, newTriggerInGroup );
        }
    },
    sortTriggers(state, { groupIndex, oldIndex, newIndex }) {
        state.tag.tTagGroupTriggers[groupIndex].triggersInGroups.splice(newIndex, 0,
            state.tag.tTagGroupTriggers[groupIndex].triggersInGroups.splice(oldIndex, 1)[0]);
    },
    removeTriggers(state, { groupIndex, indexTrigger }) {
        state.tag.tTagGroupTriggers[groupIndex].triggersInGroups.splice( indexTrigger, 1 );
    },
    deleteGroup(state, { groupIndex }) {
        state.tag.tTagGroupTriggers.splice( groupIndex, 1 );
    },
    setProject(state, { project_id }) {
        state.tag.project_id = project_id
    },
    setSourceTagId(state, sourceTagId) {
        state.tag.tag_source_id = sourceTagId
    },
    setPublicSourceTagId(state, tagId) {
        state.tag.public_tag_source_id = tagId
    },

    setException(state, {groupIndex, exception}) {
        state.tag.tTagGroupTriggers[groupIndex].exception = exception
    },
    setLeadTime(state, {indexGroup, indexTrigger, lead_time }) {
        state.tag.tTagGroupTriggers[indexGroup].triggersInGroups[indexTrigger].lead_time = lead_time
    },
    setLeadTimeUser(state, {indexGroup, indexTrigger, lead_time_text }) {
        state.tag.tTagGroupTriggers[indexGroup].triggersInGroups[indexTrigger].lead_time_text = lead_time_text
    },
    setIndicatorLocalStorage(state, status) {
        state.localStorageHaveTag = status
    },

    /*                                                                              dataControl */
    setFieldsTag(state, fieldsTag) {
        state.tag.tagSourceId.tagFieldName = fieldsTag
    },
    setHandlerMethod(state, handler) {
        if (isString(handler.data)) {
            handler.data = JSON.parse(handler.data)
        }

        Vue.set(state.tag.tagHandlers, handler.id, handler)
    },
    deleteHandlerMethod(state, handler_id) {
        Vue.delete(state.tag.tagHandlers, handler_id)
    },
    handlerTurnOn(state, handler_id) {
        state.tag.tagHandlers[handler_id].status = 1
    },
    handlerTurnOff(state, handler_id) {
        state.tag.tagHandlers[handler_id].status = 0
    },
    handlerTurnArhive(state, handler_id) {
        state.tag.tagHandlers[handler_id].status = 2
    }

}

export const getters = {
    getTag(state) {
        return state.tag;
    },
    getMaxIndexGroup(state) {
        return state.tag.tTagGroupTriggers.length - 1
    },
    hasLocalStorageTag(state) {
        return state.localStorageHaveTag
    },
    getHandlers(state) {
        return state.tag.tagHandlers
    }
}

export const actions = {
    init({ state, dispatch, commit }, id) {
        return new Promise(function(resolve) {
            if (id === '0') {
                let data = localStorage.getItem('newTagServiceData')
                console.log(data)
                if (data) {
                    commit('setTag',  JSON.parse(localStorage.getItem('newTagServiceData')));
                } else {
                    commit('setTag',patternTag)
                }

                if (state.tag.tag_source_id !== null) {
                    dispatch('getFieldsTag')
                }
                resolve(getters.getTag)
                return
            }
            dispatch('getTag', id).then(() => {
                resolve(getters.getTag)
            })
        })


        // if (id === '0') {
        //     let data = localStorage.getItem('newTagServiceData')
        //     if (data) {
        //         commit('setTag',  JSON.parse(localStorage.getItem('newTagServiceData')));
        //     } else {
        //         commit('setTag',patternTag)
        //     }
        //
        //     if (state.tag.tag_source_id !== null) {
        //         dispatch('getFieldsTag')
        //     }
        //     return
        // }
        // dispatch('getTag', id)
    },
    addNewGroupTrigger({ commit, getters }) {
        commit('addGroupTrigger',  lodash.cloneDeep(patternGroupTag) )
        return getters.getMaxIndexGroup
    },
    addTriggerInGroup({ commit },  { groupIndex, trigger, indexElem } ) {
        let newTriggerInGroup = lodash.cloneDeep(patternTriggerInGroup)
        newTriggerInGroup.trigger = trigger
        commit('addTriggerInGroup', { groupIndex, newTriggerInGroup, indexElem } )
    },
    clearTagLocal({ commit }) {
        commit('setTag',{})
        window.localStorage.removeItem('newTagServiceData')
        commit('setIndicatorLocalStorage', false)
    },
    saveTagLocal({ commit, state, getters }) {
        if (state.tag.id !== '0') {
            return
        }
        window.localStorage.setItem('newTagServiceData', JSON.stringify(getters.getTag))
        commit('setIndicatorLocalStorage', true)
    },
    getTag({commit, dispatch }, id) {
        const request = {
            method: 'get',
            path: '/tags/tag/view?id=' + id
        }

        return (new api()).sendRequest(request).then(response => {
            if (response.data.status === 'success') {
                commit('setTag',  response.data.data );
            }
            if (response.data.status === 'error') {
                dispatch('notification/error', 'error', { root: true }); //@TODO: текст ошибки
            }
        })
    },
    getFieldsTag({ state, commit, dispatch }) {
        const request = {
            method: 'get',
            path: 'tags/tag-source/get-tag-fields?id=' + state.tag.tag_source_id
        }
        //@TODO: добавить проверку хендлеров, на наличие ошибок после обновы полей
        return (new api()).sendRequest(request).then(response => {
            if (response.data.status === 'success') {
                commit('setFieldsTag',  response.data.data );
            }
            if (response.data.status === 'error') {
                dispatch('notification/error', 'error', ''); //@TODO: текст ошибки
            }
        })
    },
    saveTag({ getters, dispatch, commit }, clearTagLocal = true) {
        const request = {
            method: 'post',
            path: 'tags/tag/create-update',
            headers: {
                'Content-Type': 'application/json'
            },
            properties: JSON.stringify(getters.getTag)
        }

        return (new api()).sendRequest(request).then(response => {
            if (response.data.status === 'success') {
                commit('setTag',  response.data.data );
                if (clearTagLocal) {
                    dispatch('clearTagLocal')
                }
                return response.data.data
            }
            if (response.data.status === 'error') {
                dispatch('notification/error', 'error', { root: true }); //@TODO: текст ошибки
                return false
            }
        })
    }
}
