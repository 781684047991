export const patternAddPropertiesIcon = {
    main: {
        main: {
            svgIcon: {
                name: 'svgIcon',
                unit: '',
                required: false,
                type: 'SvgIconWidget',
                default: ''
            },
            IconAddPropFill: {
                name: "IconAddPropFill",
                type: "DuplicatePropWidget",
                relation: false,
                required: false,
                props: [
                    {
                        block: 'css',
                        section: 'background',
                        nameProp: 'fill'
                    }
                ]
            },
            IconAddPropBackground: {
                name: "IconAddPropBackground",
                type: "DuplicatePropWidget",
                relation: false,
                required: false,
                props: [
                    {
                        block: 'css',
                        section: 'background',
                        nameProp: 'backgroundColor'
                    }
                ]
            },
        }
    },
    css: {
        background: {
            fill: {
                name: 'fill',
                unit: '',
                required: false,
                type: 'color'
            },
            backgroundPositionY: null,
            backgroundPositionX: null,
            backgroundRepeatY: null,
            backgroundRepeatX: null,
            backgroundImage: null,
            backgroundTitle: null,
            backgroundSize: null,
            maskTitle: null,
            maskImage: null,
            maskRepeatX: null,
            maskRepeatY: null,
            maskPositionX: null,
            maskPositionY: null
        },
        text: null
    }
}
