export const patternAddPropertiesWidgetAttach = {
    main: {
        main: {
            childMainElemId: {
                name: 'childMainElemId',
                unit: null,
                required: false,
                type: 'number',
                callback: function(vue, value) {
                    if (vue.elemCurrentProp[vue.nameProp] === undefined) {
                        return
                    }
                    vue.tagData.htmlElements[value].tag = 'div'
                    // console.log(vue.elemCurrentProp[vue.nameProp])
                    // if (!value)
                    // if (value === 'tooltip') {
                    //     vue.$delete(vue.elem.css, 'position')
                    //     // vue.TDESaddServiceElem('serviceArrow', vue.indexElem)
                    // } else {
                    //     vue.$set(vue.elem.css, 'position', 'absolute')
                    //     // vue.TDESDelServiceElem('serviceArrow', vue.indexElem)
                    // }
                }
            }
        }
    }
}
