export const patternAddPropertiesSlider = {
    main: {
        main: {
            attr_autoplay: {
                name: 'attr_autoplay',
                required: false,
                relation: false,
                type: 'checkbox'
            },
            "attr_autoplay-delay": {
                name: 'attr_autoplay-delay',
                required: false,
                relation: false,
                type: 'number',
                default: { val: 3000 },
            },
            attr_pagination: {
                name: 'attr_pagination',
                required: false,
                relation: false,
                type: 'checkbox'
            },
        }
    }
}
