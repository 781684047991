export default function dateFilter (value, format = 'date') {
    value = new Date(value * 1000)
    let options = {}
    if (format === 'date') {
        options = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }
    } else if (format === 'time') {
        options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        }
    } else if (format === 'datetime') {
        options = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        }
    }

    return new Intl.DateTimeFormat('en-GB', options).format(value)
}
