import api from "@/helpers/fakebackend/api";

export const state = {
    triggers: [],
    sideBarSelectEvent: null
}

export const mutations = {
    setTriggers(state, value) {
        state.triggers = value
    },
    addTrigger(state, value) {
        state.triggers.push(value)
    },
    updateTrigger(state, { index, trigger }) {
        state.triggers[index] = trigger
    },
    setSideBarSelectEvent(state, value) {
        state.sideBarSelectEvent = value
    },
    deleteTrigger(state, { index }) {
        state.triggers.splice( index, 1 )
    }
}

export const getters = {
    getTriggers(state) {
        return state.triggers;
    },
    getSideBarSelectEvent(state) {
        return state.sideBarSelectEvent;
    }
}

export const actions = {
    async getTriggers({ commit, dispatch }, project_id) {
        const request = {
            method: 'get',
            path: 'tags/trigger/index',
            properties: {
                project_id: project_id
            }
        }

        return (new api()).sendRequest(request).then(response => {
            if (response.data.status === 'success') {
                commit('setTriggers',  response.data.data);

            }
            if (response.data.status === 'error') {
                dispatch('notification/error', 'error', { root: true }); //@TODO: текст ошибки
            }
        })
    },
    async createTriggers({ commit, dispatch }, {trigger, project_id}) {
        const request = {
            method: 'post',
            path: 'tags/trigger/create',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            properties: {
                project_id: project_id,
                event: trigger.event,
                data:  JSON.stringify(trigger.data),
                name: trigger.name
            }
        }

        return (new api()).sendRequest(request).then(response => {
            if (response.data.status === 'success') {
                commit('addTrigger', response.data.data)
                return true
            }
            if (response.data.status === 'error') {
                dispatch('notification/error', 'error', { root: true }); //@TODO: текст ошибки
                return false
            }
        })
    },
    async saveTriggers({ commit, dispatch }, {trigger, project_id, index}) {
        console.log(trigger, trigger.data, JSON.stringify(trigger.data))
        const request = {
            method: 'post',
            path: 'tags/trigger/update',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            properties: {
                trigger_id: trigger.id,
                project_id: project_id,
                event: trigger.event,
                data:  JSON.stringify(trigger.data),
                name: trigger.name
            }
        }

        return (new api()).sendRequest(request).then(response => {
            if (response.data.status === 'success') {
                commit('updateTrigger', {index, trigger: response.data.data})
                return true
            }
            if (response.data.status === 'error') {
                dispatch('notification/error', 'error', { root: true }); //@TODO: текст ошибки
                return false
            }
        })
    },
    async deleteTriggers({ commit, dispatch }, {trigger, index}) {
        const request = {
            method: 'post',
            path: 'tags/trigger/delete',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            properties: {
                trigger_id: trigger.id
            }
        }

        return (new api()).sendRequest(request).then(response => {
            if (response.data.status === 'success') {
                commit('deleteTrigger', {index})
                return true
            }
            if (response.data.status === 'error') {
                dispatch('notification/error', 'error', { root: true }); //@TODO: текст ошибки
                return false
            }
        })
    }
}
