import { projectsService } from '@/helpers/fakebackend/projects.service';
import store from '@/state/store'
import i18n from '@/i18n.js'

export const state = {
    projects: null,
    currentProject: null
}

export const actions = {
     init({dispatch, commit}, projects = null) {
        let projectId = localStorage.getItem('projectId')
        if (projects !== null) {
            commit('setProjects', projects)
            if (projectId && projects[projectId] !== undefined) {
                commit('setCurrentProject', projects[projectId])
            } else {
                projectId = Object.keys(projects)[0];
                dispatch('selectProject', projectId)
            }

            return
        }

        dispatch('downloadProjects').then(result => {
            if (projectId && result[projectId] !== undefined) {
                commit('setCurrentProject', result[projectId])
            } else {
                projectId = Object.keys(result)[0];
            }
            dispatch('selectProject', projectId)
            commit('loadingOff')
        })
    },
    async downloadProjects({ commit }) {
        return projectsService.getProjects().then(result => {
            commit('setProjects', result)
            return result
        })
    },
    selectProject({ commit, getters }, projectId) {
        if (store.getters['tag/hasLocalStorageTag']) {
            store.dispatch('notification/error', i18n.t('Projects.Notice.ChangeProjectNotSaveTag'));
            return false
        }
        let result = getters.getProjects
        commit('setCurrentProject', result[projectId])
        store.dispatch('statistics/changeProject')
        localStorage.setItem('projectId', projectId)
    },

    async getCurrentProjects({getters}) {
        // let authProcessing = store.getters['init/getProcessing']
        // if (authProcessing !== null) {
        //     store.getters['init/getProcessing'].then(() => {
        //         return getters.getCurrentProjects
        //     })
        // } else {
        //     return getters.getCurrentProjects
        // }

        return new Promise(function(resolve) {
            let authProcessing = store.getters['init/getProcessing']
            if (authProcessing !== null) {
                store.getters['init/getProcessing'].then(() => {
                    resolve(getters.getCurrentProjects)
                })
            } else {
                resolve(getters.getCurrentProjects)
            }
        })
    }
};

export const getters = {
    getLoading(state) {
        state.loading
    },
    getProjects(state) {
        return state.projects
    },
    getCurrentProjects(state) {
        return state.currentProject
    }
}

export const mutations = {
    loadingOff(state) {
        state.loading = false
    },
    setProjects(state, projects) {
        state.projects = projects;
    },
    setCurrentProject(state, currentProject) {
        state.currentProject = currentProject
    }
};

