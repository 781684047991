// var borderStyleOptions = function (pos) {
//     let border = 'border' + pos
//     return [
//         {
//             name: 'not set',
//             code: null
//         },
//         {
//             name: 'none',
//             code: 'none',
//             style: {[border]: '2px none rgb(255 62 96)', 'padding': '4px'}
//         },
//         {
//             name: 'dotted',
//             code: 'dotted',
//             style: {[border]: '2px dotted rgb(255 62 96)', 'padding': '4px'}
//         },
//         {
//             name: 'dashed',
//             code: 'dashed',
//             style: {[border]: '2px dashed rgb(255 62 96)', 'padding': '4px'}
//         },
//         {
//             name: 'solid',
//             code: 'solid',
//             style: {[border]: '2px solid rgb(255 62 96)', 'padding': '4px'}
//         },
//         {
//             name: 'double',
//             code: 'double',
//             style: {[border]: '2px double rgb(255 62 96)', 'padding': '4px'}
//         },
//         {
//             name: 'groove',
//             code: 'groove',
//             style: {[border]: '2px groove rgb(255 62 96)', 'padding': '4px'}
//         },
//         {
//             name: 'ridge',
//             code: 'ridge',
//             style: {[border]: '2px ridge rgb(255 62 96)', 'padding': '4px'}
//         },
//         {
//             name: 'inset',
//             code: 'inset',
//             style: {[border]: '2px inset rgb(255 62 96)', 'padding': '4px'}
//         },
//         {
//             name: 'outset',
//             code: 'outset',
//             style: {[border]: '2px outset rgb(255 62 96)', 'padding': '4px'}
//         }
//     ]
// }
// const borderClassWidth = 'form-inline col-4 pr-0 pl-0'
// const borderClassStyle = 'form-inline col-4 pr-0 pl-1'
// const borderClassColor = 'form-inline col-1 pr-0 pl-1'
// const borderRadiusClass = 'form-inline col-4 pr-0 pl-1'

export const PatternBasePropertiesElemCss = {
    position: {
        // display: {
        //     name: 'display',
        //     required: true,
        //     relation: false,
        //     default: 'block',
        //     type: "select",
        //     options: [
        //         {
        //             name: 'show',
        //             code: 'block'
        //         },
        //         {
        //             name: 'hide',
        //             code: 'none'
        //         },
        //     ]
        // },
        h4WidthAndHeight: {
            type: 'h4',
            text: 'WidthAndHeight'
        },
        width: {
            name: 'width',
            required: {
                block: 'css',
                relation: 'position',
                value: 'absolute',
                required: true,
                defaultRequired: false
            },
            default: 'auto',
            relation: false,
            type: 'number',
            placeholder: 'auto',
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            step: "0.01",
        },
        height: {
            name: 'height',
            required: {
                block: 'css',
                relation: 'position',
                value: 'absolute',
                required: true,
                defaultRequired: false
            },
            default: 'auto',
            placeholder: 'auto',
            relation: false,
            type: 'number',
            step: "0.01",
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
        },
        maxWidth: {
            name: 'maxWidth',
            default: 'unset',
            relation: false,
            type: 'number',
            step: "0.01",
            placeholder: 'auto',
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
        },
        maxHeight: {
            name: 'maxHeight',
            default: 'unset',
            relation: false,
            type: 'number',
            step: "0.01",
            placeholder: 'auto',
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
        },
        minWidth: {
            name: 'minWidth',
            default: 'unset',
            relation: false,
            type: 'number',
            step: "0.01",
            placeholder: 'auto',
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
        },
        minHeight: {
            name: 'minHeight',
            default: 'unset',
            relation: false,
            type: 'number',
            step: "0.01",
            placeholder: 'auto',
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
        },
        h4Position: {
            type: 'h4',
            text: 'position'
        },
        position: {
            name: 'position',
            required: true,
            relation: false,
            type: "select",
            options: [
                {
                    name: 'absolute',
                    code: 'absolute'
                },
                {
                    name: 'fixed',
                    code: 'fixed'
                },
                {
                    name: 'relative',
                    code: 'relative'
                },
            ]
        },
        display: {
            name: 'display',
            required: true,
            relation: false,
            type: "select",
            options: [
                // {
                //     name: 'default',
                //     code: 'default'
                // },
                {
                    name: 'block',
                    code: 'block'
                },
                {
                    name: 'none',
                    code: 'none'
                },
                {
                    name: 'inline',
                    code: 'inline'
                },
                {
                    name: 'inline-block',
                    code: 'inline-block'
                },
                {
                    name: 'flex',
                    code: 'flex'
                }
            ],
            // callback: function(vue, value, actions) {
            //     if (value !== 'default') {
            //         return
            //     }
            //
            // }
        },
        flexWrap: {
            name: 'flexWrap',
            required: true,
            relation: {
                block: 'css',
                properties: 'display',
                value: ['flex']
            },
            type: "radioGroup",
            reset: true,
            options: [
                {
                    html: '<i class="eeIcon icon-flex-nowrap" title="nowrap"></i>',
                    code: 'nowrap'
                },
                {
                    html: '<i class="eeIcon icon-flex-wrap" title="wrap"></i>',
                    code: 'wrap'
                }
                // {
                //     html: '<i class="ri-layout-column-line" title="wrap-reverse"></i>',
                //     code: 'wrap-reverse'
                // }
            ]
        },
        justifyContent: {
            name: 'justifyContent',
            required: true,
            relation: {
                block: 'css',
                properties: 'display',
                value: ['flex']
            },
            type: "radioGroup",
            reset: true,
            options: [
                {
                    html: '<i class="eeIcon icon-justify-start" title="flex-start"></i>',
                    code: 'flex-start'
                },
                {
                    html: '<i class="eeIcon icon-justify-center" title="center"></i>',
                    code: 'center'
                },
                {
                    html: '<i class="eeIcon icon-justify-end" title="flex-end"></i>',
                    code: 'flex-end'
                },
                {
                    html: '<i class="eeIcon icon-justify-space-between" title="space-between"></i>',
                    code: 'space-between'
                },
                {
                    html: '<i class="eeIcon icon-justify-space-around" title="space-around"></i>',
                    code: 'space-around'
                },
                {
                    html: '<i class="eeIcon icon-justify-space-even" title="space-evenly"></i>',
                    code: 'space-evenly'
                }
            ]
        },
        alignItems: {
            name: 'alignItems',
            required: true,
            relation: {
                block: 'css',
                properties: 'display',
                value: ['flex']
            },
            type: "radioGroup",
            reset: true,
            options: [
                {
                    html: '<i class="eeIcon AlignItemsStartRow" title="flex-start"></i>',
                    code: 'flex-start'
                },
                {
                    html: '<i class="eeIcon AlignItemsEndRow" title="flex-end"></i>',
                    code: 'flex-end'
                },
                {
                    html: '<i class="eeIcon AlignItemsCenterRow" title="center"></i>',
                    code: 'center'
                },
                {
                    html: '<i class="eeIcon AlignItemsStretchRow" title="stretch"></i>',
                    code: 'stretch'
                },
                {
                    html: '<i class="eeIcon AlignItemsBaselineRow" title="baseline"></i>',
                    code: 'baseline'
                }
            ]
        },
        flexDirection: {
            name: 'flexDirection',
            required: true,
            relation: {
                block: 'css',
                properties: 'display',
                value: ['flex']
            },
            type: "radioGroup",
            reset: true,
            options: [
                {
                    html: '<i class="ri-layout-column-line" title="row"></i>',
                    code: 'row'
                },
                {
                    html: '<i class="ri-layout-column-fill" title="row-reverse"></i>',
                    code: 'row-reverse'
                },
                {
                    html: '<i class="ri-layout-row-line" title="column"></i>',
                    code: 'column'
                },
                {
                    html: '<i class="ri-layout-row-fill" title="column-reverse"></i>',
                    code: 'column-reverse'
                }
            ]
        },
        alignContent: {
            name: 'alignContent',
            required: true,
            relation: {
                block: 'css',
                properties: 'flexWrap',
                value: ['wrap', 'wrap-reverse']
            },
            type: "radioGroup",
            reset: true,
            options: [
                {
                    html: '<i class="mdi mdi-align-horizontal-left" title="flex-start"></i>',
                    code: 'flex-start'
                },
                {
                    html: '<i class="mdi mdi-align-horizontal-right" title="flex-end"></i>',
                    code: 'flex-end'
                },
                {
                    html: '<i class="mdi mdi-align-horizontal-center" title="center"></i>',
                    code: 'center'
                },
                {
                    html: '<i class="mdi mdi-all-inclusive" title="space-between"></i>',
                    code: 'space-between'
                },
                {
                    html: '<i class="mdi mdi-all-inclusive" title="space-around"></i>',
                    code: 'space-around'
                },
                {
                    html: '<i class="mdi mdi-all-inclusive" title="stretch"></i>',
                    code: 'stretch'
                },
                {
                    html: '<i class="mdi mdi-all-inclusive" title="space-evenly"></i>',
                    code: 'space-evenly'
                }
            ]
        },
        flexGrow: {
            name: 'flexGrow',
            required: true,
            relation: {
                parent: true,
                block: 'css',
                properties: 'display',
                value: ['flex'],
            },
            type: "selectoNumber",
            reset: true,
            options: [
                {
                    name: 'inherit',
                    code: 'inherit',
                },
                {
                    name: 'initial',
                    code: 'initial',
                },
                {
                    name: 'unset',
                    code: 'unset',
                }
            ]
        },
        flexShrink: {
            name: 'flexShrink',
            required: true,
            relation: {
                parent: true,
                block: 'css',
                properties: 'display',
                value: ['flex'],
            },
            type: "selectoNumber",
            reset: true,
            options: [
                {
                    name: 'inherit',
                    code: 'inherit',
                },
                {
                    name: 'initial',
                    code: 'initial',
                },
                {
                    name: 'unset',
                    code: 'unset',
                }
            ]
        },
        flexBasis: {
            name: 'flexBasis',
            required: true,
            relation: {
                parent: true,
                block: 'css',
                properties: 'display',
                value: ['flex'],
            },
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            type: "selectoNumber",
            reset: true,
            options: [
                {
                    name: 'auto',
                    code: 'auto',
                }
            ]
        },
        alignSelf: {
            name: 'alignSelf',
            required: true,
            relation: {
                parent: true,
                block: 'css',
                properties: 'display',
                value: ['flex'],
            },
            type: "radioGroup",
            reset: true,
            options: [
                {
                    html: '<i class="mdi mdi-align-horizontal-left" title="flex-start"></i>',
                    code: 'flex-start'
                },
                {
                    html: '<i class="mdi mdi-align-horizontal-right" title="flex-end"></i>',
                    code: 'flex-end'
                },
                {
                    html: '<i class="mdi mdi-align-horizontal-center" title="center"></i>',
                    code: 'center'
                },
                {
                    html: '<i class="eeIcon AlignItemsStretchRow " title="stretch"></i>',
                    code: 'stretch'
                },
                {
                    html: '<i class="eeIcon AlignItemsBaselineRow" title="baseline"></i>',
                    code: 'baseline'
                }
            ]
        },
        PatternLocationWidgetAbsolute: {
            name: null,
            type: "PatternLocationElem",
            relation: {
                block: 'css',
                properties: 'position',
                value: ['absolute', 'fixed']
            },
        },
        h4Coordinates: {
            type: 'h4',
            text: 'Coordinates',
            relation: {
                block: 'css',
                properties: 'position',
                value: ['absolute', 'fixed']
            },
        },
        top: {
            name: 'top',
            required: false,
            relation: {
                block: 'css',
                properties: 'position',
                value: ['absolute', 'fixed']
            },
            default: 'unset',
            type: 'number',
            step: "0.01",
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
        },
        left: {
            name: 'left',
            required: false,
            relation: {
                block: 'css',
                properties: 'position',
                value: ['absolute', 'fixed']
            },
            default: 'unset',
            type: 'number',
            step: "0.01",
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}]
        },
        bottom: {
            name: 'bottom',
            required: false,
            relation: {
                block: 'css',
                properties: 'position',
                value: ['absolute', 'fixed']
            },
            default: 'unset',
            type: 'number',
            step: "0.01",
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}]
        },
        right: {
            name: 'right',
            required: false,
            relation: {
                block: 'css',
                properties: 'position',
                value: ['absolute', 'fixed']
            },
            default: 'unset',
            type: 'number',
            step: "0.01",
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}]
        }
    },
    border: {
        elem2: {
            type: 'h4',
            text: 'borderRadius'
        },
        BorderRadiusWidget: {
            name: null,
            type: "BorderRadiusWidget",
            relation: false,
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}]
        },
        // borderRadiusTop: {
        //     type: 'inline',
        //     text: 'borderRadiusTop',
        //     classLabel: 'col-4',
        //     relation: false,
        //     elems: {
        //         borderTopLeftRadius: {
        //             name: 'borderTopLeftRadius',
        //             required: false,
        //             relation: false,
        //             type: "number",
        //             unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
        //             class: borderRadiusClass
        //         },
        //         borderTopRightRadius: {
        //             name: 'borderTopRightRadius',
        //             required: false,
        //             relation: false,
        //             type: "number",
        //             unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
        //             class: borderRadiusClass,
        //         }
        //     }
        // },
        // borderRadiusBottom: {
        //     type: 'inline',
        //     text: 'borderRadiusBottom',
        //     classLabel: 'col-4',
        //     relation: false,
        //     elems: {
        //         borderBottomLeftRadius: {
        //             name: 'borderBottomLeftRadius',
        //             required: false,
        //             relation: false,
        //             type: "number",
        //             unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
        //             class: borderRadiusClass,
        //         },
        //         borderBottomRightRadius: {
        //             name: 'borderBottomRightRadius',
        //             required: false,
        //             relation: false,
        //             type: "number",
        //             unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
        //             class: borderRadiusClass,
        //         }
        //     }
        // },
        // elemSimpleBorder: {
        //     type: 'h4',
        //     text: 'SimpleBorder'
        // },
        // borderSingle: {
        //     type: 'inline',
        //     text: 'border',
        //     relation: false,
        //     elems: {
        //         borderWidth: {
        //             name: 'borderWidth',
        //             required: false,
        //             relation: false,
        //             type: "number",
        //             unit: ['px'],
        //             class: borderClassWidth,
        //         },
        //         borderStyle: {
        //             name: 'borderStyle',
        //             required: false,
        //             relation: false,
        //             type: "select",
        //             class: borderClassStyle,
        //             options: borderStyleOptions(''),
        //         },
        //         borderColor: {
        //             name: 'borderColor',
        //             required: false,
        //             relation: false,
        //             type: "color",
        //             class: borderClassColor,
        //         }
        //     }
        // },
        elemBorderExpanded: {
            type: 'h4',
            text: 'BorderExpanded'
        },
        BorderWidget: {
            name: null,
            type: "BorderWidget",
            relation: false,
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            options: [
                {
                    html: '<i class="mdi mdi-close-thick" ></i>',
                    code: 'none'
                },
                {
                    html: '<div class="eeIcon customIconBorderSolid"></div>',
                    code: 'solid'
                },
                {
                    html: '<div class="eeIcon customIconDashedSolid"></div>',
                    code: 'dashed'
                },
                {
                    html: '<div class="eeIcon customIconDottedSolid"></div>',
                    code: 'dotted'
                }
            ]
        },

        // borderExpandedTop: {
        //     type: 'inline',
        //     text: 'borderExpandedTop',
        //     relation: false,
        //     elems: {
        //         borderTopWidth: {
        //             name: 'borderTopWidth',
        //             required: false,
        //             relation: false,
        //             type: "number",
        //             unit: ['px'],
        //             class: borderClassWidth
        //         },
        //         borderTopStyle: {
        //             name: 'borderTopStyle',
        //             required: false,
        //             relation: false,
        //             type: "select",
        //             class: borderClassStyle,
        //             options: borderStyleOptions('-top')
        //         },
        //         borderTopColor: {
        //             name: 'borderTopColor',
        //             required: false,
        //             relation: false,
        //             type: "color",
        //             class: borderClassColor
        //         }
        //     }
        // },
        // borderExpandedBottom: {
        //     type: 'inline',
        //     text: 'borderExpandedBottom',
        //     relation: false,
        //     elems: {
        //         borderBottomWidth: {
        //             name: 'borderBottomWidth',
        //             required: false,
        //             relation: false,
        //             type: "number",
        //             unit: ['px'],
        //             class: borderClassWidth
        //         },
        //         borderBottomStyle: {
        //             name: 'borderBottomStyle',
        //             required: false,
        //             relation: false,
        //             type: "select",
        //             class: borderClassStyle,
        //             options: borderStyleOptions('-bottom')
        //         },
        //         borderBottomColor: {
        //             name: 'borderBottomColor',
        //             required: false,
        //             relation: false,
        //             type: "color",
        //             class: borderClassColor
        //         }
        //     }
        // },
        // borderExpandedLeft: {
        //     type: 'inline',
        //     text: 'borderExpandedLeft',
        //     relation: false,
        //     elems: {
        //         borderLeftWidth: {
        //             name: 'borderLeftWidth',
        //             required: false,
        //             relation: false,
        //             type: "number",
        //             unit: ['px'],
        //             class: borderClassWidth
        //         },
        //         borderLeftStyle: {
        //             name: 'borderLeftStyle',
        //             required: false,
        //             relation: false,
        //             type: "select",
        //             class: borderClassStyle,
        //             options: borderStyleOptions('-left')
        //         },
        //         borderLeftColor: {
        //             name: 'borderLeftColor',
        //             required: false,
        //             relation: false,
        //             type: "color",
        //             class: borderClassColor
        //         }
        //     }
        // },
        // borderExpandedRight: {
        //     type: 'inline',
        //     text: 'borderExpandedRight',
        //     relation: false,
        //     elems: {
        //         borderRightWidth: {
        //             name: 'borderRightWidth',
        //             required: false,
        //             relation: false,
        //             type: "number",
        //             unit: ['px'],
        //             class: borderClassWidth
        //         },
        //         borderRightStyle: {
        //             name: 'borderRightStyle',
        //             required: false,
        //             relation: false,
        //             type: "select",
        //             class: borderClassStyle,
        //             options: borderStyleOptions('-right')
        //         },
        //         borderRightColor: {
        //             name: 'borderRightColor',
        //             required: false,
        //             relation: false,
        //             type: "color",
        //             class: borderClassColor
        //         }
        //     }
        // },
    },
    background: {
        backgroundColor: {
            name: 'backgroundColor',
            unit: '',
            required: false,
            type: 'color',
            labelCol: 5
        },
        backgroundTitle: {
            type: 'h4',
            text: 'backgroundTitle'
        },
        backgroundImage: {
            name: 'backgroundImage',
            unit: '',
            required: false,
            default: 'none',
            type: 'image', // imgWidget
            callback: function(vue, value, actions) {
                if (value === null) {
                    actions.push({obj: this.elemCurrentProp, nameProp: 'backgroundRepeatX', val: null, type: 'delete'})
                    actions.push({obj: this.elemCurrentProp, nameProp: 'backgroundRepeatY', val: null, type: 'delete'})
                    actions.push({obj: this.elemCurrentProp, nameProp: 'backgroundSize', val: null, type: 'delete'})
                }
            }
        },
        // backgroundClip : {
        //     name: 'backgroundClip',
        //     required: false,
        //     relation: false,
        //     type: "select",
        //     options: [
        //         {
        //             name: 'Фон внутри границ',
        //             code: 'padding-box',
        //             style: { 'border': '2px dashed #000', 'padding': '4px', 'background': 'rgb(255 214 0)', 'backgroundClip': 'padding-box' }
        //         },
        //         {
        //             name: 'Фон под границами',
        //             code: 'border-box',
        //             style: { 'border': '3px dashed #000', 'padding': '4px', 'background': 'rgb(255 214 0)', 'backgroundClip': 'border-box' }
        //         },
        //         {
        //             name: 'Фон учитывает отступ',
        //             code: 'content-box',
        //             style: { 'border': '2px dashed #000', 'padding': '4px', 'background': 'rgb(255 214 0)', 'backgroundClip': 'content-box' }
        //         }
        //     ]
        // }
        backgroundRepeat : {
            name: 'backgroundRepeat',
            required: false,
            relation: {
                block: 'css',
                properties: 'backgroundImage',
                // value: '!null',
                not_value: ['none']
            },
            type: "widgetBackgroundRepeat",
            default: 'repeat',
            options: [
                {
                    text: 'no-repeat',
                    value: 'no-repeat',
                },
                {
                    text: 'repeat',
                    value: 'repeat',
                },
                {
                    text: 'space',
                    value: 'space',
                },
                {
                    text: 'round',
                    value: 'round',
                },
            ]
        },
        backgroundPositionX: {
            name: 'backgroundPositionX',
            required: false,
            relation: {
                block: 'css',
                properties: 'backgroundImage',
                not_value: ['none']
            },
            type: "selectoNumber",
            default: {
                val: 0,
                unit: 'px'
            },
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            options: [
                {
                    name: 'left',
                    code: 'left'
                },
                {
                    name: 'right',
                    code: 'right'
                },
                {
                    name: 'center',
                    code: 'center'
                }
            ]
        },
        backgroundPositionY: {
            name: 'backgroundPositionY',
            required: false,
            relation: {
                block: 'css',
                properties: 'backgroundImage',
                not_value: ['none']
            },
            type: "selectoNumber",
            default: {
                val: 0,
                unit: 'px'
            },
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            options: [
                {
                    name: 'top',
                    code: 'top'
                },
                {
                    name: 'bottom',
                    code: 'bottom'
                },
                {
                    name: 'center',
                    code: 'center'
                }
            ]
        },
        backgroundSize: {
            name: 'backgroundSize',
            unit: '',
            required: false,
            relation: {
                block: 'css',
                properties: 'backgroundImage',
                not_value: ['none']
            },
            type: 'widgetBackgroundSize',
            default: 'auto',
            options: [
                {
                    text: 'no set',
                    value: 'null',
                },
                {
                    text: 'auto',
                    value: 'auto',
                },
                {
                    text: 'cover',
                    value: 'cover',
                },
                {
                    text: 'contain',
                    value: 'contain',
                },
                {
                    text: 'manual',
                    value: 'manual',
                },
            ]
        },
        maskTitle: {
            type: 'h4',
            text: 'maskTitle'
        },
        maskImage: {
            name: 'maskImage',
            unit: '',
            required: false,
            type: 'image', // imgWidget
            default: 'none',
            callback: function(vue, value, actions) {
                if (value === null) {
                    actions.push({obj: this.elemCurrentProp, nameProp: 'maskRepeatX', val: null, type: 'delete'})
                    actions.push({obj: this.elemCurrentProp, nameProp: 'maskRepeatY', val: null, type: 'delete'})
                    actions.push({obj: this.elemCurrentProp, nameProp: 'maskSize', val: null, type: 'delete'})
                }
            }
        },
        maskRepeat : {
            name: 'maskRepeat',
            required: false,
            relation: {
                block: 'css',
                properties: 'maskImage',
                // value: '!null',
                not_value: ['none']
            },
            type: "widgetMaskRepeat",
            default: 'repeat',
            options: [
                {
                    text: 'no-repeat',
                    value: 'no-repeat',
                },
                {
                    text: 'repeat',
                    value: 'repeat',
                },
                {
                    text: 'space',
                    value: 'space',
                },
                {
                    text: 'round',
                    value: 'round',
                },
            ]
        },
        // maskRepeatX : {
        //     name: 'maskRepeatX',
        //     required: false,
        //     relation: {
        //         block: 'css',
        //         properties: 'maskImage',
        //         not_value: ['none']
        //     },
        //     type: "select",
        //     default: 'repeat',
        //     options: [
        //         {
        //             name: 'no-repeat',
        //             code: 'no-repeat',
        //         },
        //         {
        //             name: 'repeat',
        //             code: 'repeat',
        //         },
        //         {
        //             name: 'space',
        //             code: 'space',
        //         },
        //         {
        //             name: 'round',
        //             code: 'round',
        //         },
        //     ]
        // },
        // maskRepeatY : {
        //     name: 'maskRepeatY',
        //     required: false,
        //     relation: {
        //         block: 'css',
        //         properties: 'maskImage',
        //         not_value: ['none']
        //     },
        //     type: "select",
        //     default: 'repeat',
        //     options: [
        //         {
        //             name: 'no-repeat',
        //             code: 'no-repeat',
        //         },
        //         {
        //             name: 'repeat',
        //             code: 'repeat',
        //         },
        //         {
        //             name: 'space',
        //             code: 'space',
        //         },
        //         {
        //             name: 'round',
        //             code: 'round',
        //         },
        //     ]
        // },
        maskPositionX: {
            name: 'maskPositionX',
            required: false,
            relation: {
                block: 'css',
                properties: 'maskImage',
                not_value: ['none']
            },
            type: "selectoNumber",
            // default: {
            //     val: 0,
            //     unit: 'px'
            // },
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            options: [
                {
                    name: 'top',
                    code: 'top'
                },
                {
                    name: 'bottom',
                    code: 'bottom'
                },
                {
                    name: 'left',
                    code: 'left'
                },
                {
                    name: 'right',
                    code: 'right'
                },
                {
                    name: 'center',
                    code: 'center'
                }
            ]
        },
        maskPositionY: {
            name: 'maskPositionY',
            required: false,
            relation: {
                block: 'css',
                properties: 'maskImage',
                not_value: ['none']
            },
            type: "selectoNumber",
            // default: {
            //     val: 0,
            //     unit: 'px'
            // },
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            options: [
                {
                    name: 'top',
                    code: 'top'
                },
                {
                    name: 'bottom',
                    code: 'bottom'
                },
                {
                    name: 'left',
                    code: 'left'
                },
                {
                    name: 'right',
                    code: 'right'
                },
                {
                    name: 'center',
                    code: 'center'
                }
            ]
        },
        maskSize: {
            name: 'maskSize',
            unit: '',
            required: false,
            relation: {
                block: 'css',
                properties: 'maskImage',
                not_value: ['none']
            },
            type: 'widgetMaskSize',
            default: 'auto',
            options: [
                {
                    text: 'no set',
                    value: 'null',
                },
                {
                    text: 'auto',
                    value: 'auto',
                },
                {
                    text: 'cover',
                    value: 'cover',
                },
                {
                    text: 'contain',
                    value: 'contain',
                },
                {
                    text: 'manual',
                    value: 'manual',
                },
            ]
        },
        boxShadowBox: {
            type: 'h4',
            text: 'ShadowBox'
        },
        boxShadow: {
            name: "boxShadow",
            type: "BoxShadowWidget",
            relation: false,
            required: false
        },
    },
    indent: {
        h4Margin: {
            type: 'h4',
            text: 'Margin'
        },
        marginTop: {
            name: 'marginTop',
            required: false,
            relation: false,
            type: "selectoNumber",
            default: {
                val: 0,
                unit: 'px'
            },
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            options: [
                {
                    name: 'auto',
                    code: 'auto'
                },
                {
                    name: 'inherit',
                    code: 'inherit'
                }
            ]
        },
        marginBottom: {
            name: 'marginBottom',
            required: false,
            relation: false,
            default: {
                val: 0,
                unit: 'px'
            },
            type: "selectoNumber",
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            options: [
                {
                    name: 'auto',
                    code: 'auto'
                },
                {
                    name: 'inherit',
                    code: 'inherit'
                }
            ]
        },
        marginLeft: {
            name: 'marginLeft',
            required: false,
            relation: false,
            default: {
                val: 0,
                unit: 'px'
            },
            type: "selectoNumber",
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            options: [
                {
                    name: 'auto',
                    code: 'auto'
                },
                {
                    name: 'inherit',
                    code: 'inherit'
                }
            ]
        },
        marginRight: {
            name: 'marginRight',
            required: false,
            relation: false,
            default: {
                val: 0,
                unit: 'px'
            },
            type: "selectoNumber",
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            options: [
                {
                    name: 'auto',
                    code: 'auto'
                },
                {
                    name: 'inherit',
                    code: 'inherit'
                }
            ]
        },
        h4Padding: {
            type: 'h4',
            text: 'Padding'
        },
        paddingTop: {
            name: 'paddingTop',
            required: false,
            relation: false,
            type: "selectoNumber",
            default: {
                val: 0,
                unit: 'px'
            },
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            options: [
                {
                    name: 'inherit',
                    code: 'inherit'
                }
            ]
        },
        paddingBottom: {
            name: 'paddingBottom',
            required: false,
            relation: false,
            type: "selectoNumber",
            default: {
                val: 0,
                unit: 'px'
            },
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            options: [
                {
                    name: 'inherit',
                    code: 'inherit'
                }
            ]
        },
        paddingLeft: {
            name: 'paddingLeft',
            required: false,
            relation: false,
            type: "selectoNumber",
            default: {
                val: 0,
                unit: 'px'
            },
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            options: [
                {
                    name: 'inherit',
                    code: 'inherit'
                }
            ]
        },
        paddingRight: {
            name: 'paddingRight',
            required: false,
            relation: false,
            type: "selectoNumber",
            default: {
                val: 0,
                unit: 'px'
            },
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            options: [
                {
                    name: 'inherit',
                    code: 'inherit'
                }
            ]
        },
    },
    text: {
        fontSize: {
            name: 'fontSize',
            required: false,
            relation: false,
            type: "selectoNumber",
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'},{name: 'em',code: 'em'}],
            options: [
                {
                    name: 'inherit',
                    code: 'inherit'
                }
            ]
        },
        lineHeight: {
            name: 'lineHeight',
            required: false,
            placeholder: 'auto',
            relation: false,
            type: 'selectoNumber',
            unit: [{name: 'px',code: 'px'},{name: '%',code: '%'}],
            options: [
                {
                    name: 'normal',
                    code: 'normal'
                },
                {
                    name: 'inherit',
                    code: 'inherit'
                },
                {
                    name: 'initial',
                    code: 'initial'
                },
                {
                    name: 'unset',
                    code: 'unset'
                }


            ]
        },
        fontWeight: {
            name: 'fontWeight',
            required: false,
            relation: false,
            type: "select",
            options: [
                {
                    name: '100',
                    code: '100',
                    style: {'fontWeight': '100'}
                },
                {
                    name: '200',
                    code: '200',
                    style: {'fontWeight': '200'}
                },
                {
                    name: '300',
                    code: '300',
                    style: {'fontWeight': '300'}
                },
                {
                    name: '400',
                    code: '400',
                    style: {'fontWeight': '400'}
                },
                {
                    name: '500',
                    code: '500',
                    style: {'fontWeight': '500'}
                },
                {
                    name: '600',
                    code: '600',
                    style: {'fontWeight': '600'}
                },
                {
                    name: '700',
                    code: '700',
                    style: {'fontWeight': '700'}
                },
                {
                    name: '800',
                    code: '800',
                    style: {'fontWeight': '800'}
                },
                {
                    name: '900',
                    code: '900',
                    style: {'fontWeight': '900'}
                }
            ]
        },
        color: {
            name: 'color',
            required: false,
            type: 'color'
        },
        fontFamily: {
            name: 'fontFamily',
            required: false,
            relation: false,
            type: "select",
            options: [
                {
                    name: 'Arial',
                    code: 'Arial',
                    style: {'fontFamily': 'Arial'}
                },
                {
                    name: 'Arial Black',
                    code: 'Arial Black',
                    style: {'fontFamily': 'Arial Black'}
                },
                {
                    name: 'Comic Sans MS',
                    code: 'Comic Sans MS',
                    style: {'fontFamily': 'Comic Sans MS'}
                },
                {
                    name: 'Courier New',
                    code: 'Courier New',
                    style: {'fontFamily': 'Courier New'}
                },
                {
                    name: 'Georgia',
                    code: 'Georgia',
                    style: {'fontFamily': 'Georgia'}
                },
                {
                    name: 'Impact',
                    code: 'Impact',
                    style: {'fontFamily': 'Impact'}
                },
                {
                    name: 'Times New Roman',
                    code: 'Times New Roman',
                    style: {'fontFamily': 'Times New Roman'}
                },
                {
                    name: 'Trebuchet MS',
                    code: 'Trebuchet MS',
                    style: {'fontFamily': 'Trebuchet MS'}
                },
                {
                    name: 'Verdana',
                    code: 'Verdana',
                    style: {'fontFamily': 'Verdana'}
                },
                {
                    name: 'Impact',
                    code: 'Impact',
                    style: {'fontFamily': 'Impact'}
                },
                {
                    name: 'inherit',
                    code: 'inherit',
                    style: {'fontFamily': 'inherit'}
                }
            ]
        },
        textAlign: {
            name: 'textAlign',
            required: false,
            relation: false,
            type: "select",
            options: [
                {
                    name: 'center',
                    code: 'center',
                    style: {'textAlign': 'center'}
                },
                {
                    name: 'justify',
                    code: 'justify',
                    style: {'textAlign': 'justify'}
                },
                {
                    name: 'left',
                    code: 'left',
                    style: {'textAlign': 'left'}
                },
                {
                    name: 'right',
                    code: 'right',
                    style: {'textAlign': 'right'}
                },
                {
                    name: 'inherit',
                    code: 'inherit',
                    style: {'textAlign': 'inherit'}
                },
                {
                    name: 'start',
                    code: 'start',
                    style: {'textAlign': 'start'}
                },
                {
                    name: 'end',
                    code: 'end',
                    style: {'textAlign': 'end'}
                }
            ]
        },

        textDecorationLine: { //@TODO: добавить мультиселект и сделать свойство мультиселектом
            name: 'textDecorationLine',
            required: false,
            relation: false,
            type: "select",
            options: [
                {
                    name: 'none',
                    code: 'none',
                    style: {'textDecorationLine': 'none'}
                },
                {
                    name: 'underline',
                    code: 'underline',
                    style: {'textDecorationLine': 'underline'}
                },
                {
                    name: 'overline',
                    code: 'overline',
                    style: {'textDecorationLine': 'overline'}
                },
                {
                    name: 'line-through',
                    code: 'line-through',
                    style: {'textDecorationLine': 'line-through'}
                }
            ]
        },
        textDecorationStyle: {
            name: 'textDecorationStyle',
            required: false,
            relation: {
                block: 'css',
                properties: 'textDecorationLine',
                not_value: ['none']
            },
            type: "select",
            options: [
                {
                    name: 'solid',
                    code: 'solid',
                    style: {'textDecorationLine': 'underline', 'textDecorationStyle': 'solid'}
                },
                {
                    name: 'double',
                    code: 'double',
                    style: {'textDecorationLine': 'underline', 'textDecorationStyle': 'double'}
                },
                {
                    name: 'dotted',
                    code: 'dotted',
                    style: {'textDecorationLine': 'underline', 'textDecorationStyle': 'dotted'}
                },
                {
                    name: 'dashed',
                    code: 'dashed',
                    style: {'textDecorationLine': 'underline', 'textDecorationStyle': 'dashed'}
                },
                {
                    name: 'wavy',
                    code: 'wavy',
                    style: {'textDecorationLine': 'underline', 'textDecorationStyle': 'wavy'}
                }
            ]
        },
        // textDecorationColor: { //@TODO: переделать виджет, добавить значение currentColor
        //     name: 'textDecorationColor',
        //     required: false,
        //     type: 'color',
        //     relation: {
        //         block: 'css',
        //         properties: 'textDecorationLine',
        //         not_value: ['none']
        //     }
        // },


        textShadow: {
            name: "textShadow",
            type: "TextShadowWidget",
            relation: false,
            required: false
        }
    },
    transform: {
        CssTransformWidget: {
            name: "transform",
            type: "CssTransformWidget",
            relation: false,
            required: false
        },
        opacity: {
            name: 'opacity',
            placeholder: '1',
            required: false,
            default: 1,
            relation: false,
            type: 'range',
            min: 0,
            max: 1,
            step: 0.01,
            unit: null
        },
        cursor: {
            name: 'cursor',
            required: false,
            relation: false,
            type: "select",
            options: [
                {
                    name: 'inherit',
                    code: 'inherit',
                },
                {
                    name: 'initial',
                    code: 'initial',
                },
                {
                    name: 'default',
                    code: 'default',
                },
                {
                    name: 'pointer',
                    code: 'pointer'
                },
                {
                    name: 'help',
                    code: 'help'
                },
                {
                    name: 'move',
                    code: 'move'
                },
                {
                    name: 'text',
                    code: 'text'
                },
                {
                    name: 'progress',
                    code: 'progress'
                },
                {
                    name: 'wait',
                    code: 'wait'
                },
                {
                    name: 'crosshair',
                    code: 'crosshair'
                }
            ]
        },
        zIndex: {
            name: 'zIndex',
            required: false,
            relation: false,
            type: "selectoNumber",
            default: {
                val: 0
            },
            units: null,
            options: [
                {
                    name: 'auto',
                    code: 'auto'
                },
                {
                    name: 'inherit',
                    code: 'inherit'
                },
                {
                    name: 'initial',
                    code: 'initial'
                }
            ]
        },
        overflowX: {
            name: 'overflowX',
            required: false,
            relation: false,
            type: "select",
            options: [
                {
                    name: 'visible',
                    code: 'visible',
                },
                {
                    name: 'hidden',
                    code: 'hidden'
                },
                {
                    name: 'scroll',
                    code: 'scroll'
                },
                {
                    name: 'auto',
                    code: 'auto'
                }
            ]
        },
        overflowY: {
            name: 'overflowY',
            required: false,
            relation: false,
            type: "select",
            options: [
                {
                    name: 'visible',
                    code: 'visible',
                },
                {
                    name: 'hidden',
                    code: 'hidden'
                },
                {
                    name: 'scroll',
                    code: 'scroll'
                },
                {
                    name: 'auto',
                    code: 'auto'
                }
            ]
        },
    },
}
