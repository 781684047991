export const patternAddPropertiesLottie = {
    main: {
        main: {
            lottie: {
                name: 'lottie',
                unit: '',
                required: false,
                type: 'LottieWidget',
                default: ''
            },
            mode: {
                name: 'mode',
                required: false,
                relation: false,
                type: "select",
                options: [
                    {
                        name: 'normal',
                        code: 'normal'
                    },
                    {
                        name: 'bounce',
                        code: 'bounce'
                    }
                ]
            },
            loop: {
                name: 'loop',
                unit: '',
                required: false,
                relation: false,
                type: 'checkbox'
            },
            autoplay: {
                name: 'autoplay',
                unit: '',
                required: false,
                relation: false,
                type: 'checkbox'
            },
            controls: {
                name: 'controls',
                unit: '',
                required: false,
                relation: false,
                type: 'checkbox'
            },
            hover: {
                name: 'hover',
                unit: '',
                required: false,
                relation: false,
                type: 'checkbox'
            },
            speed: {
                name: 'speed',
                default: {
                    val: 1,
                    unit: ''
                },
                step: "0.01",
                relation: false,
                type: 'number',
                placeholder: 'auto',
                unit: null
            }
        }
    }
}
