import api from "@/helpers/fakebackend/api";

export const statService = {
    getStatForChart
};

async function getStatForChart(params = {}) {
    const request = {
        method: 'get',
        path: 'stat/stat/get-event-stat',
        headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: params,
        properties: {}
    }
    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}