export const state = {
    tagData: {}
}

export const mutations = {
    setTagData(state, tagData) {
        state.tagData = tagData
    }
}

export const getters = {
    getTagData(state) {
        return state.tagData;
    },
}

export const actions = {

}
